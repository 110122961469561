import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';

import 'emerald-ui/lib/styles.css';
import 'emerald-ui/lib/styles-ceb.css';

// State
import { client } from './api/graphql';
import { token } from './api/http/auth';
import { StateProvider } from './store/context';
import { initialState, mainReducer } from './store/app.state';

// Utils
import Cookie from './utils/cookie';
import Config from './config';

// Components
import Appbar from './components/Appbar';
import Wrapper from './components/common/Wrapper';

// Pages
import Home from './pages/Home';

const { UNIQUE_USER, LAUNCHPAD_URL, LEGACY_COOKIE_NAME } = Config;

class App extends Component {
  state = {
    isLoadingToken: true,
    user: null,
  };

  async componentDidMount() {
    const cookies = Cookie();
    if (cookies[LEGACY_COOKIE_NAME]) {
      const getUser = await token(cookies[LEGACY_COOKIE_NAME]);

      if (getUser && getUser.roles && getUser.roles.includes(UNIQUE_USER)) {
        this.setState({ isLoadingToken: false, user: getUser });
      } else {
        window.location.assign(`${LAUNCHPAD_URL}/logout`);
      }
    } else {
      window.location.assign(LAUNCHPAD_URL);
    }
  }

  render() {
    const { isLoadingToken } = this.state;
    return !isLoadingToken ? (
      <Router>
        <ApolloProvider client={client}>
          <StateProvider initialState={initialState} reducer={mainReducer}>
            <div className="App">
              <Appbar user={this.state.user} />
              <Wrapper>
                <Route exact path="/" render={() => <Home />} />
              </Wrapper>
            </div>
          </StateProvider>
        </ApolloProvider>
      </Router>
    ) : null;
  }
}

export default App;
