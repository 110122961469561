const { REACT_APP_ENV } = process.env;

export const ERROR_API = {
  GET_ADS: 'ERROR_GET_ADS',
  GET_CLICKS: 'ERROR_GET_CLICKS',
  GET_VIEWS: 'ERROR_GET_VIEWS',
  PAGINATE_AD: 'ERROR_PAGINATE_AD',
  CHECK_AD: 'ERROR_CHECK_AD',
  GET_MEDIAS: 'ERROR_GET_MEDIAS',
  GET_AD: 'ERROR_GET_AD',
  SET_AD: 'ERROR_SET_AD',
  UPDATE_AD: 'ERROR_UPDATE_AD',
  DISABLE_AD: 'ERROR_DISABLE_AD',
  COURSES_API: 'ERROR_COURSES_API',
  OFFERING_DATE_API: 'ERROR_OFFERING_DATE_API',
};

export const AD_TYPE = 'course';
export const STATUS_DEFAULT = 'active';
export const STATUS_INACTIVE = 'inactive';
export const STATUS_DISABLE = 'disabled';
export const GLOBAL_MEDIAS_SA = ['Dashboard'];

function getEnvironments(env) {
  const config = {
    REQUEST_TIMEOUT: 30000,
    UNIQUE_USER: 'SYSADM',
    DEFAULT_TOKEN_ADS_API: 'QWERTYABCD',
  };

  // Override settings according to the enviroment
  switch (env) {
    case 'staging':
      config.URL_ADS_API = 'https://ads-services.test.cebroker.com';
      config.LAUNCHPAD_URL = 'https://test.launchpad.cebroker.com';
      config.HOME_URL = 'https://test.cebroker.com';
      config.SECURE_URL = 'https://test.secure.cebroker.com';
      config.LICENSEE_INTERNAL_API = 'https://licensees.test.cebroker.com/api';
      config.SECURE_GATEWAY = 'https://test.secure.cebroker.com/public/pb_auth_gateway.aspx';
      config.LEGACY_COOKIE_NAME = 'test_cebtoken';
      config.URL_SETTINGS_SERVICE_GRAPHQL_API = 'https://api.settings.test.cebroker.com/graphql';
      config.DEFAULT_TOKEN_GRAPHQL_API = 'Y2ViLWNvcmUtYWRzLXNlcnZpY2VzOnI8TVVaPmRNTjNhRXc2OXE=';
      config.URL_COURSES_API = 'https://api.courses.test.cebroker.com';
      config.URL_COURSES_OFFERING_API = 'https://api.courses.test.cebroker.com';
      config.DEFAULT_TOKEN_ADS_API = 'QURTX1NJVEU6Q2BWVkh7OGJTME5XZHIifSMpcXEsV25sZUd8Qzk5';
      config.AUTH_ACCOUNT_API = 'https://test.accounts.cebroker.com';
      config.STORAGE_API = 'https://test.storage.cebroker.com/CEBroker/';
      break;
    case 'demo':
      config.URL_ADS_API = 'https://ads-services.demo.cebroker.com';
      config.LAUNCHPAD_URL = 'https://demo.launchpad.cebroker.com';
      config.LICENSEE_INTERNAL_API = 'https://licensees.demo.cebroker.com/api';
      config.HOME_URL = 'https://demo.cebroker.com';
      config.SECURE_URL = 'https://demo.secure.cebroker.com';
      config.SOCKET_URL = 'https://demo.apps.cebroker.com/';
      config.SOCKET_INTERVAL = 7000;
      config.SECURE_GATEWAY = 'https://demo.secure.cebroker.com/public/pb_auth_gateway.aspx';
      config.LEGACY_COOKIE_NAME = 'demo_cebtoken';
      break;
    case 'production':
      config.URL_ADS_API = 'https://ads-services.cebroker.com';
      config.LAUNCHPAD_URL = 'https://launchpad.cebroker.com';
      config.LICENSEE_INTERNAL_API = 'https://licensees.cebroker.com/api';
      config.HOME_URL = 'https://cebroker.com';
      config.SECURE_URL = 'https://secure.cebroker.com';
      config.SECURE_GATEWAY = 'https://secure.cebroker.com/public/pb_auth_gateway.aspx';
      config.LEGACY_COOKIE_NAME = 'cebtoken';
      config.URL_SETTINGS_SERVICE_GRAPHQL_API = 'https://api.settings.cebroker.com/graphql';
      config.DEFAULT_TOKEN_GRAPHQL_API = 'Y2ViLWNvcmUtYWRzLXNlcnZpY2VzOnc4VmdHUG40KTM0NDw0LUY=';
      config.URL_COURSES_API = 'https://api.courses.cebroker.com';
      config.URL_COURSES_OFFERING_API = 'https://api.courses.cebroker.com';
      config.DEFAULT_TOKEN_ADS_API = 'QURTX1NJVEU6KmlaNHBrem9ON3ljYTRTakY+QUdHJUBGRj8iI2BM';
      config.AUTH_ACCOUNT_API = 'https://accounts.cebroker.com';
      config.STORAGE_API = 'https://storage.cebroker.com/cebroker/';
      break;
    default:
  }

  return config;
}

export default getEnvironments(REACT_APP_ENV);
